import React, { useEffect, useState } from "react";
import styles from "./AFGCHeader.module.scss";
import AFGCLogo from "../../commons/afgc-logo/AFGCLogo";
import LogoIcon from "../../../images/icons/v2/logo.inline.svg";
import MenuIcon from "../../../images/icons/v2/menu-burger.inline.svg";
import DarkMode from "../../../images/icons/dark.inline.svg";
import { IconSizes } from "../../../enums/icon-sizes";
import cx from "classnames";
import CloseIcon from "../../../images/icons/cross-icon.inline.svg";
import SVGContainer from "../../commons/svg-container/SVGContainer";
import { Link } from "gatsby";
import { RouteLinks } from "../../../utils/route-links";
import { HeaderType } from "../../../enums/header-type.enum";

interface Props {
  close?: boolean;
  clicked?: () => void;
  logoClicked?: () => void;
  className?: string;
  headerType?: HeaderType;
}

const AFGCHeader: React.FC<Props> = (props: Props) => {
  const { close = false, headerType = HeaderType.BLACK } = props;
  const [selected, setSelected] = useState<string>();

  useEffect(() => {
    if (typeof window !== undefined) {
      setSelected(window.location.pathname);
    }
  }, []);

  let headerClassType = styles.black;
  if (headerType === HeaderType.WHITE) {
    headerClassType = styles.white;
  }

  return (
    <header
      className={cx(
        styles.header,
        headerType === HeaderType.BLACK && styles.header__black,
        headerType === HeaderType.GREY && styles.header__grey,
        props.className ?? ""
      )}
    >
      <div className={styles.header__content}>
        <div className={cx(styles.logo, styles.padding)}>
          <AFGCLogo
            onClick={props.logoClicked}
            className={headerClassType}
            dataTestE2E="logo"
          >
            <LogoIcon />
          </AFGCLogo>
          <p className={cx(styles.logo__professional, headerClassType)}>
            A Few Good Clicks{" "}
          </p>
        </div>
        <div className={cx(styles.navigation)}>
          <ul className={headerClassType}>
            <li
              className={cx(
                styles.padding,
                selected?.includes("who") ? styles.navigation__selected : ""
              )}
            >
              <Link
                to={RouteLinks.whoAmI}
                className="link"
                data-test-e2e="whoami"
              >
                Who Am I
              </Link>
            </li>
            <li
              className={cx(
                styles.padding,
                selected?.includes("contact") ? styles.navigation__selected : ""
              )}
            >
              <Link
                to={RouteLinks.contact}
                className="link"
                data-test-e2e="contact"
              >
                Contact Me
              </Link>
            </li>
          </ul>
          {/* <SVGContainer
            iconSize={IconSizes.IC__30}
            className={cx(
              styles.darkMode,
              headerType === HeaderType.GREY && styles.darkMode__grey
            )}
          >
            <DarkMode />
          </SVGContainer> */}
          <div
            className={cx(
              styles.divider,
              headerType === HeaderType.GREY && styles.divider__grey
            )}
          >
            &nbsp;
          </div>
          <div
            className={cx(styles.menu, styles.padding, headerClassType)}
            onClick={props.clicked}
            data-test-e2e={close ? "menu-close" : "menu"}
          >
            <p>{close ? "Close" : "Menu"}</p>
            <SVGContainer iconSize={IconSizes.IC__24}>
              {close ? <CloseIcon /> : <MenuIcon />}
            </SVGContainer>
          </div>
        </div>
      </div>
    </header>
  );
};

export default AFGCHeader;
