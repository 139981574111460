import React, { useState } from "react";
import styles from "./MenuOverlay.module.scss";
import CustomDialog from "../../commons/custom-dialog/CustomDialog";
import AFGCHeader from "../afgc-header/AFGCHeader";
import EmailIcon from "../../../images/icons/mail.inline.svg";
import PhoneIcon from "../../../images/icons/phone.inline.svg";
import CalendlyIcon from "../../../images/icons/calendly.inline.svg";
import LocationIcon from "../../../images/icons/v2/location.inline.svg";
import FacebookIcon from "../../../images/icons/facebook.inline.svg";
import InstagramIcon from "../../../images/icons/instagram.inline.svg";
import PinterestIcon from "../../../images/icons/pinterest.inline.svg";
import AFGCAnchor from "../../commons/afgc-anchor/AFGCAnchor";
import { ContactLinks } from "../../../utils/contact-links";
import Calendly from "../../commons/calendly/Calendly";
import { LOCATIONS } from "../../../utils/constants";
import { IconSizes } from "../../../enums/icon-sizes";
import { SocialLinks } from "../../../utils/social-links";
import MenuSwipable from "./menu-swipable/MenuSwipable";
import { RouteLinks } from "../../../utils/route-links";
import SVGContainer from "../../commons/svg-container/SVGContainer";
import cx from "classnames";
import { Link } from "gatsby";
import { MenuOverlayOptions } from "../../../enums/menu-overlay-options";
import { graphql, useStaticQuery } from "gatsby";
import { GqlListResponse } from "../../../models/gql-list-response.model";
import { PortfolioGroup } from "../../../models/portfolio-group.model";
import AsteriskIcon from "../../../images/icons/asterisk-transparent.svg";
import { getPortfolioGroupLink } from "../../../utils/link.helper";

interface Props {
  closePopUp: () => void;
  isOpen: boolean;
  selected?: MenuOverlayOptions | string;
}

const query = graphql`
  {
    portfolioGroups: allMarkdownRemark(
      filter: { fields: { category: { eq: "portfolioGroups" } } }
      sort: { order: ASC, fields: fileAbsolutePath }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            cta
          }
        }
      }
    }

    metadata: site {
      siteMetadata {
        show_portfolio_types
      }
    }
  }
`;

const MenuOverlay: React.FC<Props> = (props: Props) => {
  const [opened, setOpened] = useState(0);

  const queryResult = useStaticQuery(query);

  const showTypes: boolean =
    queryResult.metadata.siteMetadata.show_portfolio_types;

  const portfolioGroupsRaw: GqlListResponse<PortfolioGroup> =
    queryResult.portfolioGroups;
  const portfolioGroups = portfolioGroupsRaw.edges;

  const typeLinks: { text: string; route: string }[] = [];
  portfolioGroups.map(el => {
    const {
      node: {
        frontmatter: { title, slug },
      },
    } = el;
    typeLinks.push({
      text: title,
      route: getPortfolioGroupLink(slug),
    });
  });

  const contactInfo = () => (
    <>
      <AFGCAnchor
        ariaLabel="Mail Link"
        url={`mailto:${ContactLinks.mail}`}
        className={cx(styles.info, styles.contact)}
      >
        <SVGContainer>
          <EmailIcon />
        </SVGContainer>
        <p>{ContactLinks.mail}</p>
      </AFGCAnchor>
      <AFGCAnchor
        ariaLabel="Phone Link"
        url={`tel:${ContactLinks.phone}`}
        className={cx(styles.info, styles.contact)}
      >
        <SVGContainer>
          <PhoneIcon />
        </SVGContainer>
        <p>{ContactLinks.phoneDisplay}</p>
      </AFGCAnchor>
      <div className={cx(styles.info, styles.contact)}>
        <SVGContainer>
          <CalendlyIcon />
        </SVGContainer>
        <Calendly text="Schedule a call" />
      </div>
    </>
  );

  const places = () => (
    <>
      {LOCATIONS.map((item, index) => (
        <li key={index} className={cx(styles.info, styles.places)}>
          <SVGContainer iconSize={IconSizes.IC__24}>
            <LocationIcon />
          </SVGContainer>
          <p>{item}</p>
        </li>
      ))}
    </>
  );

  const social = () => (
    <>
      <AFGCAnchor
        ariaLabel="Share on Facebook"
        url={SocialLinks.facebook}
        className={styles.socialRow}
      >
        <SVGContainer>
          <FacebookIcon />
        </SVGContainer>
        <p>Facebook</p>
      </AFGCAnchor>
      <AFGCAnchor
        ariaLabel="Share on Instagram"
        url={SocialLinks.instagram}
        className={styles.socialRow}
      >
        <SVGContainer>
          <InstagramIcon />
        </SVGContainer>
        <p>Instagram</p>
      </AFGCAnchor>
      <AFGCAnchor
        ariaLabel="Share on Pinterest"
        url={SocialLinks.pinterest}
        className={styles.socialRow}
      >
        <SVGContainer>
          <PinterestIcon />
        </SVGContainer>
        <p>Pinterest</p>
      </AFGCAnchor>
    </>
  );

  const navigationLinks = [
    { text: "Home", route: RouteLinks.index },
    { text: "Portfolio", route: RouteLinks.portfolio },
    { text: "Blog", route: RouteLinks.blogsAll },
    { text: "Who Am I", route: RouteLinks.whoAmI },
    { text: "Contact Me", route: RouteLinks.contact },
  ];

  const handleMenuClose = (link: string) => {
    if (typeof window !== undefined) {
      const { pathname } = window.location;
      if (pathname === link) {
        props.closePopUp();
      }
    }
  };

  return (
    <CustomDialog closePopUp={props.closePopUp} isOpen={props.isOpen}>
      <div className={styles.container} data-test-e2e="menu-container">
        <AFGCHeader
          data-test-e2e="menu-close"
          close
          clicked={props.closePopUp}
          logoClicked={props.closePopUp}
        />
        <div className={styles.mobile}>
          <MenuSwipable
            heading="Website"
            textList={navigationLinks.map(el => el.text)}
            linkList={navigationLinks.map(el => el.route)}
            opened={opened === 0}
            handleClick={() => setOpened(0)}
            selected={props.selected}
            closeMenu={props.closePopUp}
          />
          {showTypes && (
            <MenuSwipable
              heading="Types"
              textList={typeLinks.map(el => el.text)}
              linkList={typeLinks.map(el => el.route)}
              opened={opened === 1}
              handleClick={() => setOpened(1)}
              selected={props.selected}
              closeMenu={props.closePopUp}
            />
          )}
          <MenuSwipable
            heading="Contact Info"
            opened={opened === 2}
            handleClick={() => setOpened(2)}
          >
            {contactInfo()}
          </MenuSwipable>
          <MenuSwipable
            heading="Places"
            opened={opened === 3}
            handleClick={() => setOpened(3)}
          >
            {places()}
          </MenuSwipable>
          <MenuSwipable
            heading="Social Networks"
            opened={opened === 4}
            handleClick={() => setOpened(4)}
          >
            {social()}
          </MenuSwipable>
        </div>
        <div className={styles.asterisk}>
          <img src={AsteriskIcon} alt="Asterisk Icon" />
        </div>
        <div className={styles.desktop}>
          <section className={styles.leftChild}>
            <div
              className={cx(
                styles.leftChild__content,
                !showTypes && styles.leftChild__showTypes
              )}
            >
              <div className={styles.column}>
                <h6>Navigation</h6>
                {navigationLinks.map((el, index) => {
                  let className = "link";
                  if (props.selected === el.text.toLowerCase()) {
                    className = cx(styles.leftChild__selected, "link");
                  }
                  return (
                    <h3 key={index}>
                      <Link
                        to={el.route}
                        className={className}
                        onClick={() => handleMenuClose(el.route)}
                      >
                        {el.text}
                      </Link>
                    </h3>
                  );
                })}
              </div>
              {showTypes && (
                <div className={styles.column}>
                  <h6>Types</h6>
                  {typeLinks.map((el, index) => {
                    let className = "link";
                    if (
                      el.text
                        .toLowerCase()
                        .includes(props.selected ? props.selected : "#")
                    ) {
                      className = cx(styles.leftChild__selected, "link");
                    }
                    return (
                      <h3 key={index}>
                        <Link
                          to={el.route}
                          className={className}
                          onClick={() => handleMenuClose(el.route)}
                        >
                          {el.text}
                        </Link>
                      </h3>
                    );
                  })}
                </div>
              )}
            </div>
          </section>
          <section className={styles.rightChild}>
            <div className={styles.rightChild__content}>
              <div className={styles.greyContainer}>
                <h5>Contact info</h5>
                <div className="column">{contactInfo()}</div>
              </div>
              <div className={styles.greyContainer}>
                <h5>Places</h5>
                <div className="column">{places()}</div>
              </div>
              <div className={styles.greyContainer}>
                <div className={styles.rightChild__row}>{social()}</div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </CustomDialog>
  );
};

export default MenuOverlay;
