import React from "react";
import styles from "./MenuSwipable.module.scss";
import ChevronUpIcon from "../../../../images/icons/chevron-up.inline.svg";
import ChevronDownIcon from "../../../../images/icons/chevron-down.inline.svg";
import { SlideDown } from "react-slidedown";
import { Link } from "gatsby";
import cx from "classnames";
import { MenuOverlayOptions } from "../../../../enums/menu-overlay-options";
import SVGContainer from "../../../commons/svg-container/SVGContainer";

interface Props {
  heading: string;
  opened: boolean;
  handleClick: Function;
  textList?: string[];
  linkList?: string[];
  children?: React.ReactNode;
  selected?: MenuOverlayOptions | string;
  closeMenu?: () => void;
}

const MenuSwipable: React.FC<Props> = (props: Props) => {
  const handleMenuClose = (link: string) => {
    if (typeof window !== undefined) {
      const { pathname } = window.location;
      if (pathname === link) {
        props.closeMenu!();
      }
    }
  };

  if (props.children) {
    return (
      <div className={cx("column", styles.MenuSwipable)}>
        <li
          className={styles.MenuSwipable__heading}
          onClick={() => props.handleClick()}
        >
          {props.heading}
          <SVGContainer
            className={props.opened ? styles.upArrow : styles.downArrow}
          >
            {props.opened ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </SVGContainer>
        </li>
        <SlideDown>
          {props.opened ? (
            <div className={styles.External}>
              {props.opened ? props.children : null}
            </div>
          ) : null}
        </SlideDown>
      </div>
    );
  }
  return (
    <ul className={cx(styles.MenuSwipable, styles.Internal)}>
      <li
        className={cx(
          styles.MenuSwipable__heading,
          props.opened && styles.MenuSwipable__opened
        )}
        onClick={() => props.handleClick()}
      >
        {props.heading}
        <SVGContainer
          className={props.opened ? styles.upArrow : styles.downArrow}
        >
          {props.opened ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </SVGContainer>
      </li>
      <SlideDown>
        {props.opened ? (
          <>
            {props.textList!.map((el, index) => {
              let className = "link";
              if (props.selected && el.toLowerCase().includes(props.selected)) {
                className = cx(styles.MenuSwipable__selected, "link");
              }
              return (
                <li key={index} className={styles.MenuSwipable__children}>
                  <Link
                    to={props.linkList![index]}
                    className={className}
                    onClick={() => handleMenuClose(props.linkList![index])}
                  >
                    {el}
                  </Link>
                </li>
              );
            })}
            <li className={styles.MenuSwipable__children} />
          </>
        ) : null}
      </SlideDown>
    </ul>
  );
};

export default MenuSwipable;
